import IconsTheme from "./partials/IconsTheme";
import ParagraphSizes from "./partials/ParagraphSizes";
import HeaderTypes from "./partials/HeaderTypes";
import ButtonPartials from "./partials/ButtonPartials";
import InputPartials from "./partials/InputPartials";
import RatingItemPartials from "./partials/RatingItemPartials";
import ShipDetails from "./partials/ShipDetails";
import DividerPartials from "./partials/DividerPartials";

const Theme = {
  ...IconsTheme,
  ...ParagraphSizes,
  ...HeaderTypes,
  ...ButtonPartials,
  ...InputPartials,
  ...RatingItemPartials,
  ...ShipDetails,
  ...DividerPartials,
  color: {
    charcoal: "#253235",
    white: "#ffffff",
    transparentWhite: "rgba(255,255,255,0.7)",
    appBackgroundGrey: "#f7f7f7",
    lightGrey: "#c9c9c9",
    searchGrey: "#C9C9C9",
    grey: "rgba(0,0,0,.5)",
    darkGrey: "#454545",
    transparent: "transparent",
    alertColor: "#ED4337",
    alertBackground: "rgb(232, 244, 253)",
    landingHeaderBlue: "#235573",
    mainGreen: "#367049",
    darkGreen: "#428a5a",
  },
  font: {
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700,
      extraBold: 900,
    },
    size: {
      title: {
        h1: 7.2,
        h2: 1.75,
        h3: 2,
        h4: 1.8,
        h5: 1,
        h6: 1.4,
        h7: 1.2,
        subcategory: 5.5,
        landingPage: 5.4,
        landingPageSubheader: 3.5,
        contactFormHeader: 3.8,
        heroAlternativeText: 5.6,
        subpageHeader: 6.9,
      },
      button: {
        regular: 1,
        small: 0.75,
      },
      paragraph: {
        p1: 2.5,
        p2: 2,
        p3: 1,
        p4: 1.4,
        p5: 1.3,
        p6: 1.2,
      },
      input: 1.6,
      donwnloadLink: 1.5,
      navigation: 1,
    },
    lhRatio: {
      title: 1.2,
      paragraph: 1.5,
      input: 1.5,
    },
    icon: "icomoon",
    text: "Poppins, sans-serif",
    title: "Poppins, sans-serif",
  },
  padding: {
    main: "0 1rem",
  },
  text: {
    shadow: {
      header: "0 3px 6px rgba(0, 0, 0, 0.16)",
    },
    align: {
      left: "left",
      center: "center",
      right: "right",
      justify: "justify",
    },
    transform: {
      lowercase: "lowercase",
      uppercase: "uppercase",
      capitalize: "capitalize",
      unset: "unset",
    },
    letterSpacing: {
      regular: "0.12rem",
      unset: "unset",
    },
  },
  textStyles: {
    heading: {
      tag: "h1",
    },
  },
  borderRadius: {
    regular: "4px",
    none: "0px",
    left: "4px 0px 0px 4px",
    onlyBottom: "0 0 4px 4px",
  },
  border: {
    regular: "2px solid #3f592c",
    unset: "unset",
    bottom: "1px solid #3f592c",
    none: 0,
  },
  boolean: {
    true: true,
    false: false,
  },
};

export default Theme;
