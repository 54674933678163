import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ButtonComponent from "../../atoms/Button/Button.component";
import { StyledHeader } from "./HomepageCard.styles";
import P from "../../atoms/Paragraph/Paragraph.styles";

const HomepageCard: React.FC<{
  image?: string;
  description: string;
  link: string;
  name: string;
  available: boolean;
}> = ({ image = "", name, link, description, available }) => {
  return (
    <Row style={{ height: "100%", padding: 0, margin: 0 }}>
      <Accordion
        flush
        style={{
          borderRadius: "12px",
          border: "1px solid #DCDCDC",
          background: `white`,
          padding: 0,
          height: "100%",
        }}
      >
        <Card.Header style={{ height: "100%" }}>
          <Row style={{ gap: "108px", margin: 0, width: "100%", height: "100%" }}>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
                padding: "8px",
              }}
            >
              <img
                src={image}
                style={{
                  width: "100%",
                  maxHeight: "216px",
                  marginBottom: "16px",
                  borderTopLeftRadius: "4px",
                  borderTopRightRadius: "4px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 16px 16px",
                  height: "100%",
                }}
              >
                <StyledHeader size="h2">{name}</StyledHeader>
                <P
                  style={{
                    overflow: "hidden",
                    WebkitLineClamp: 5,
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    marginBottom: "32px",
                  }}
                >
                  {description}
                </P>
                <a
                  style={{ display: "flex", marginTop: "auto", alignSelf: "end", width: "100%" }}
                  href={available ? `${link}/` : "https://oceanscore.com/contact"}
                  target={!available ? "_blank" : "_self"}
                  rel={!available ? "noopener noreferrer" : ""}
                >
                  <ButtonComponent
                    variant={available ? "primary" : "secondary"}
                    style={{ width: "100%" }}
                  >
                    {available ? "Access" : "Contact us"}
                  </ButtonComponent>
                </a>
              </div>
            </Col>
          </Row>
        </Card.Header>
      </Accordion>
    </Row>
  );
};

export default HomepageCard;
