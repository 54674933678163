import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/organisms/NavigationBar/NavigationBar.component";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../components/atoms/Header/Header.component";
import P from "../../components/atoms/Paragraph/Paragraph.styles";
import HomepageCard from "../../components/molecules/HomepageCard/HomepageCard.component";

const Homepage: React.FC = () => {
  const permissions = localStorage.getItem("permissions");
  const [smallCardData, setSmallCardData] = useState<
    {
      name: string;
      image: string;
      link: string;
      description: string;
      available: boolean;
    }[]
  >([
    {
      name: "Ship Review",
      image: "/images/ship_review.png",
      link: "/ship-review",
      description:
        "Deep insights into entire fleets, individual vessels and managers: detailed information on emissions and 55 validated ESG scores for more than 100.000 vessels",
      available: false,
    },
    {
      name: "Compliance Manager",
      image: "/images/compliance_manager.png",
      link: "/compliance-manager",
      description:
        "All-in-one EU ETS and FuelEU management solution. Designed to simplify compliance and minimize risk.",
      available: false,
    },
    {
      name: "FuelEU Planner",
      image: "/images/fueleu_planner.png",
      link: "/fueleu",
      description:
        "Planning and budgeting tool for FuelEU exposure of fleets, sub-fleets, and pools. Asses and simulate compliance balances and plan settlement actions.",
      available: false,
    },
  ]);

  useEffect(() => {
    setSmallCardData((data) => [
      {
        ...data[0],
        available: permissions?.includes("accounts.ship_review.can_access") ?? false,
      },
      {
        ...data[1],
        available: permissions?.includes("accounts.ets.can_access") ?? false,
      },
      {
        ...data[2],
        available: permissions?.includes("accounts.fueleu.can_access") ?? false,
      },
    ]);
  }, [permissions]);

  return (
    <Container fluid style={{ background: "#fafafc", padding: 0, margin: 0 }}>
      <NavigationBar />
      <Container style={{ minHeight: "calc(100vh - 72px)", paddingBottom: "64px" }}>
        <Row>
          <Header size="h2" style={{ fontSize: "32px", lineHeight: "32px", margin: "32px 0 8px" }}>
            Products
          </Header>
        </Row>
        <Row style={{ marginBottom: "32px" }}>
          <P>Access subscribed products or contact us for more product options.</P>
        </Row>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            padding: 0,
            margin: 0,
            gap: "40px",
          }}
        >
          {smallCardData
            .filter((item) => item.available)
            .map((item, index) => (
              <Col key={index} style={{ height: "100%", padding: 0, margin: 0 }}>
                <HomepageCard
                  name={item.name}
                  image={item.image}
                  link={item.link}
                  description={item.description}
                  available={item.available}
                />
              </Col>
            ))}
          {smallCardData
            .filter((item) => !item.available)
            .map((item, index) => (
              <Col key={index} style={{ height: "100%", padding: 0, margin: 0 }}>
                <HomepageCard
                  name={item.name}
                  image={item.image}
                  link={item.link}
                  description={item.description}
                  available={item.available}
                />
              </Col>
            ))}
        </div>
      </Container>
    </Container>
  );
};

export default Homepage;
