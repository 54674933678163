import styled from "styled-components";
import {
  ColorType,
  FontWeightType,
  TransformType,
  ButtonFontSizeType,
  LetterSpacingType,
  BorderRadiusType,
  BorderType,
  ButtonVariantType,
  ButtonHeightType,
} from "../../../types/types";
import Button from "react-bootstrap/Button";

export interface ButtonComponentInterface {
  backgroundColor?: ColorType;
  borderColor?: ColorType;
  fontColor?: ColorType;
  variant?: "primary" | "secondary";
  weight?: FontWeightType;
  transform?: TransformType;
  fontSize?: ButtonFontSizeType;
  fontWeight?: FontWeightType;
  letterSpacing?: LetterSpacingType;
  borderRadius?: BorderRadiusType;
  border?: BorderType;
  width?: ButtonVariantType;
  height?: ButtonHeightType;
  padding?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export const StyledButton = styled(Button)<ButtonComponentInterface>`
  padding: ${({ padding }) => (padding ? padding : "6px 16px")};
  color: ${({ theme, fontColor, variant }) =>
    fontColor
      ? theme.color[fontColor]
      : variant === "primary"
      ? theme.color.white
      : theme.color.mainGreen};
  font-size: ${({ theme, fontSize }) =>
    fontSize ? `${theme.font.size.button[fontSize]}rem` : `${theme.font.size.button.regular}rem`};
  background-color: ${({ theme, backgroundColor, variant }) =>
    backgroundColor
      ? theme.color[backgroundColor]
      : variant === "primary"
      ? theme.color.mainGreen
      : theme.color.transparent};
  border-radius: ${({ theme, borderRadius }) =>
    borderRadius ? theme.borderRadius[borderRadius] : theme.borderRadius.regular};
  border: ${({ theme, border, variant }) =>
    border
      ? theme.border[border]
      : variant === "primary"
      ? theme.border.regular
      : theme.border.regular};
  border-color: ${({ theme, borderColor }) =>
    borderColor ? theme.color[borderColor] : theme.color.mainGreen};
  &: hover {
    background-color: ${({ theme, backgroundColor, variant }) =>
      backgroundColor
        ? theme.color[backgroundColor]
        : variant === "primary"
        ? theme.color.darkGreen
        : theme.color.transparent};
    color: ${({ theme, fontColor, variant }) =>
      fontColor
        ? theme.color[fontColor]
        : variant === "primary"
        ? theme.color.white
        : theme.color.darkGreen};
    border-color: ${({ theme, borderColor, variant }) =>
      borderColor
        ? theme.color[borderColor]
        : variant === "primary"
        ? theme.color.darkGreen
        : theme.color.darkGreen};
  }
  &: active {
    background-color: ${({ theme, backgroundColor, variant }) =>
      backgroundColor
        ? `${theme.color[backgroundColor]}!important`
        : variant === "primary"
        ? `${theme.color.darkGreen}!important`
        : `${theme.color.transparent}!important`};
    color: ${({ theme, fontColor, variant }) =>
      fontColor
        ? `${theme.color[fontColor]}!important`
        : variant === "primary"
        ? `${theme.color.white}!important`
        : `${theme.color.darkGreen}!important`};
    border-color: ${({ theme, borderColor, variant }) =>
      borderColor
        ? `${theme.color[borderColor]}!important`
        : variant === "primary"
        ? `${theme.color.darkGreen}!important`
        : `${theme.color.darkGreen}!important`};
  }
  &: disabled {
    background-color: ${({ theme, backgroundColor, variant }) =>
      backgroundColor
        ? theme.color[backgroundColor]
        : variant === "primary"
        ? theme.color.searchGrey
        : theme.color.transparent};
    border-color: ${({ theme, borderColor, variant }) =>
      borderColor
        ? theme.color[borderColor]
        : variant === "primary"
        ? theme.color.searchGrey
        : theme.color.searchGrey};
    color: ${({ theme, fontColor, variant }) =>
      fontColor
        ? theme.color[fontColor]
        : variant === "primary"
        ? theme.color.white
        : theme.color.searchGrey};
  }
`;
