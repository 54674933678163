import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { routes } from "./routes/routes";
import Homepage from "./views/Homepage/Homepage.component";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./themes/GlobalStyles";
import Theme from "./themes/MainTheme";
import ContentHandler from "./components/atoms/ContentHandler/ContentHandler.component";
import ScrollToTop from "react-scroll-to-top";
import PrivateRoute from "./routes/PrivateRoute";
import LoginRoute from "./routes/LoginRoute";
import PublicRoute from "./routes/PublicRoute";
import { Navigate } from "react-router-dom";

const Exception = React.lazy(
  () => import("./views/Exception/Exception.component")
);

const Login = React.lazy(() => import("./views/Login/Login.component"));

const Imprint = React.lazy(() => import("./views/Imprint/Imprint.component"));
const Privacy = React.lazy(() => import("./views/Privacy/Privacy.component"));
const Portfolio = React.lazy(
  () => import("./views/Portfolio/Portfolio.component")
);

const TermsAndConditions = React.lazy(
  () => import("./views/TermsAndConditions/TermsAndConditions.component")
);
const WithLoader = React.lazy(
  () => import("./components/molecules/WithLoader/WithLoader.component")
);
const Logout = React.lazy(() => import("./views/Logout/Logout.component"));
const ResetPassword = React.lazy(
  () => import("./views/ResetPassword/ResetPassword.component")
);

function App() {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={Theme}>
        <Router>
          <Suspense fallback={<WithLoader isLoading={true} />}>
            {/* <ScrollToTop
              smooth
              color={"#ffffff"}
              viewBox="0 0 16 16"
              svgPath="M11.7,6.3l-3-3C8.5,3.1,8.3,3,8,3c0,0,0,0,0,0C7.7,3,7.5,3.1,7.3,3.3l-3,3c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0L7,6.4
              V12c0,0.6,0.4,1,1,1s1-0.4,1-1V6.4l1.3,1.3c0.4,0.4,1,0.4,1.4,0C11.9,7.5,12,7.3,12,7S11.9,6.5,11.7,6.3z"
              style={{
                zIndex: 10,
                background: "rgba(0,0,0,0.4)",
                color: "#ffffff",
                borderRadius: 0,
                padding: "6px",
                right: "10px",
                bottom: "10px",
              }}
            /> */}
            <ContentHandler>
              {/* TODO: Extract Topbar there when it will be unified */}
              <Routes>
                <Route
                  path={`${routes.resetPassword}/:id?`}
                  Component={() => <ResetPassword />}
                />

                <Route
                  path={routes.login}
                  element={<LoginRoute Component={() => <Login />} />}
                />

                <Route
                  path={routes.homepage}
                  element={<PrivateRoute Component={() => <Homepage />} />}
                />
                <Route
                  path={routes.logout}
                  element={<PrivateRoute Component={() => <Logout />} />}
                />
                {/* 
                The intended route/component combo for the future

                <Route
                  path={`${routes.shipManagers}`}
                  element={<PublicRoute Component={() => <Portfolio />} />}
                />                
                */}
                <Route
                  path={routes.shipManagers}
                  element={<Navigate to="/login" />}
                />
                <Route
                  path={routes.landingPage}
                  element={<Navigate to="/login" />}
                />
                <Route Component={Exception} />
              </Routes>
              {/* Extract footer there when it will be unified */}
            </ContentHandler>
          </Suspense>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
